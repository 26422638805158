import * as Sentry from '@sentry/vue'
import useSessionStore from '~/store/session'

export default defineNuxtPlugin(() => ({
    provide: {
        throwError: (error: any, msg: string) => {
            const status = error?.response?.status || error?.status || 500
            const code = error?.code || ''
            const stack = error?.response?.data?.exception || error?.data

            const userId = useSessionStore().user?.id || 'unknown'

            const data = {
                name: error.name,
                message: error.message,
                statusCode: status,
                userId,
                dataResponse:
                    stack || error?.response?._data || 'No data provided'
            }

            const newError = new Error(
                `Error ${status} ${code}: ${msg}`,
                error
            ) as any

            if (
                status !== 401 &&
                status !== 403 &&
                status !== 404 &&
                process.env.NODE_ENV !== 'test'
            ) {
                Sentry.captureException(newError, {
                    tags: {
                        section: 'msg'
                    },
                    extra: data
                })
            } else if (process.env.NODE_ENV === 'test' || status === 404) {
                throw newError
            }
        }
    }
}))
