import type { Moods } from '#cmui/types/mood'
import type { Range } from '#cmui/types/feedback'

import { type Pages } from './pagination'

export enum FeedbackType {
    CHECKIN = 'CHECKIN',
    COMMENT = 'COMMENT',
    REPORT = 'REPORT'
}

export enum FilterFeedback {
    WITH_COMMENT = 'WITH_COMMENT',
    CHECKIN = 'CHECKIN',
    COMMENT = 'COMMENT',
    REPORT = 'REPORT',
    RATED = 'RATED',
    REPORT_WITHOUT_COMMENT = 'REPORT_WITHOUT_COMMENT'
}

export enum FilterFeedbackStatus {
    UNREAD = 'UNREAD',
    ARCHIVED = 'ARCHIVED',
    ANSWERED = 'ANSWERED'
}

export enum ChargeSuccess {
    SUCCESS = 'SUCCESS',
    FAILED = 'FAILED'
}

export enum FilterFeedbackType {
    UNREAD = 'UNREAD',
    ARCHIVED = 'ARCHIVED',
    ANSWERED = 'ANSWERED',
    WITH_COMMENT = 'WITH_COMMENT',
    CHECKIN = 'CHECKIN',
    COMMENT = 'COMMENT',
    REPORT = 'REPORT',
    RATED = 'RATED',
    REPORT_WITHOUT_COMMENT = 'REPORT_WITHOUT_COMMENT'
}

export interface FiltersFeedback {
    keyword: string
    orderColumn: string
    orderDirection: string
    pages: Pages
    feedbackTypeFilter: FilterFeedbackType[]
    moodFilter: Moods[]
    range?: Range | null
}
