import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin((nuxtApp) => {
    const environment = nuxtApp.$config.public.NODE_ENV
    const sentryDSN = nuxtApp.$config.public.SENTRY_DSN

    Sentry.init({
        Vue: nuxtApp.vueApp,
        app: [nuxtApp.vueApp],
        dsn: sentryDSN,
        environment,
        trackComponents: true,
        sampleRate: 1.0,
        tracesSampleRate: 0,
        replaysSessionSampleRate: 0,
        replaysOnErrorSampleRate: 1,
        ignoreErrors: [
            /Failed to fetch dynamically imported module/,
            /Non-Error promise rejection captured with value: Timeout/,
            /Non-Error promise rejection captured with value: Object Not Found Matching/,
            /401/,
            /403/,
            /404/,
            // Fix for apexcharts
            /getAttribute/,
            /parentNode/,
            /ResizeObserver loop completed with undelivered notifications/
        ],
        denyUrls: ['/apexcharts.common.js', '/vue3-apexcharts.js'],
        beforeSend(event: any, hint: any) {
            // Check if it is an exception, and if so, log it.
            if (event.exception) {
                // eslint-disable-next-line no-console
                console.error(
                    `[Exeption handled by Sentry]: (${hint.originalException})`,
                    { event, hint }
                )
            }

            // Continue sending to Sentry
            return event
        },
        integrations: [
            Sentry.replayIntegration({
                networkDetailAllowUrls: [
                    window.location.origin,
                    '/partners-api/partners',
                    '/partners-api/',
                    'importy-api',
                    'community-api',
                    'pool-pricy-api',
                    'api.chargemap-partners.dev',
                    /^https:\/\/api\.chargemap-partners\.dev/
                ],
                networkRequestHeaders: ['Cache-Control'],
                networkResponseHeaders: ['Referrer-Policy'],
                maskAllText: false,
                blockAllMedia: false,
                maskAllInputs: false
            })
        ]
    })

    nuxtApp.vueApp.config.errorHandler = (error: any) => {
        console.log({ message: 'ERROR CLIENT', error })
        Sentry.captureException(error)
        showError({
            statusCode: error.statusCode,
            statusMessage: error.message,
            stack: error.stack
        })
    }

    return {
        provide: {
            sentrySetContext: Sentry.setContext,
            sentrySetUser: Sentry.setUser,
            sentrySetTag: Sentry.setTag,
            sentryAddBreadcrumb: Sentry.addBreadcrumb,
            sentryCaptureException: Sentry.captureException
        }
    }
})
