import usePartnersStore from '~/store/partners'
import useNetworksStore from '~/store/networks'
import useSessionStore from '~/store/session'
import { type PartnerRole } from '~/interfaces/user'
import useEngagementsStore from '~/store/engagements'

export default defineNuxtRouteMiddleware((to, from) => {
    if (process.server) return
    const partnersStore = usePartnersStore()
    const networkStore = useNetworksStore()
    const sessionStore = useSessionStore()
    const { currentNetwork, currentCommunityNetwork } = networkStore
    const { currentPartner } = partnersStore
    const { idPartner, networkType, idNetwork } = to.params
    const { partnersRights, isSuperAdmin } = sessionStore

    useSessionStore().lastRoute = from.fullPath

    if (idPartner && idPartner !== 'undefined') {
        const partnerRole =
            partnersRights.find(
                (item: PartnerRole) =>
                    item.id === parseInt(idPartner as string, 10)
            )?.role || null

        if (!partnerRole && !isSuperAdmin) {
            partnersStore.setCurrentPartner(null)

            return navigateTo(`/partners?error=unauthorized&time=${Date.now()}`)
        }

        if (!currentPartner?.id || currentPartner.id.toString() !== idPartner) {
            partnersStore
                .fetchPartner(parseInt(idPartner as string, 10))
                .catch(() => {
                    navigateTo(
                        `/partners?error=unauthorized&time=${Date.now()}`
                    )
                })
        }
    }

    if (idPartner && idNetwork && networkType) {
        useEngagementsStore().fetchUnreadFeedbackCount(
            networkType as string,
            idPartner as string,
            idNetwork as string
        )
    }

    if (
        idNetwork ||
        networkType ||
        to.name === 'partners-idPartner-networks' ||
        to.name === 'partners-idPartner-parameters'
    ) {
        if (
            networkType &&
            !['interop', 'community'].includes(networkType as string)
        ) {
            abortNavigation(
                createError({
                    statusCode: 404,
                    statusMessage: 'Not Found'
                })
            )
        }

        if (idNetwork) {
            if (
                ((!currentCommunityNetwork?.id ||
                    currentCommunityNetwork?.id !==
                        parseInt(idNetwork as string, 10)) &&
                    networkType === 'community') ||
                ((!currentNetwork?.id ||
                    currentNetwork?.id !== parseInt(idNetwork as string, 10)) &&
                    networkType === 'interop')
            ) {
                networkStore
                    .fetchCurrentNetwork(
                        idPartner as string,
                        parseInt(idNetwork as string, 10),
                        networkType
                    )
                    .catch(() => {
                        if (!isSuperAdmin)
                            navigateTo(`/partners/${idPartner}/networks`)
                        navigateTo('/partners')
                    })
            }
        }
    }
})
