import { defineStore } from 'pinia'
import {
    type FiltersPartnerUsers,
    type FiltersPartners,
    type Partner,
    type PartnerJSON
} from '~/interfaces/partner'

import { type PartnerUser, type User } from '~/interfaces/user'
import { keysToCamel } from '~/plugins/json/utils'
import useSessionStore from './session'

const usePartnersStore = defineStore('partners', {
    state: () => ({
        currentPartner: null as Partner | null,
        loadingPartner: false,
        partners: [] as Partner[]
    }),
    getters: {
        getCurrentPartner(state) {
            return state.currentPartner
        },
        isPackageFull(state) {
            return state.currentPartner?.packageFull || false
        }
    },
    actions: {
        async fetchPartners(filters: FiltersPartners) {
            const { $api, $toCamel, $throwError } = useNuxtApp()

            const {
                keyword,
                includeAllPartners,
                pages,
                orderColumn,
                orderDirection
            } = filters
            const offset = (pages.index - 1) * pages.perPage || (0 as number)
            const limit = pages.perPage || (10 as number)

            const payload = {
                keyword: keyword || null,
                activated: includeAllPartners ? null : true,
                orderBy:
                    orderColumn && orderDirection
                        ? `${orderColumn},${orderDirection}`
                        : null,
                offset,
                limit
            }
            this.loadingPartner = true

            return $api
                .get('/partners-api/partners', {
                    params: payload
                })
                .then((response) => {
                    if (response.status === 200) {
                        const totalCount = parseInt(
                            response.headers['x-total-count'],
                            10
                        )

                        this.partners = $toCamel(response.data.items)

                        return {
                            totalItems: totalCount,
                            items: $toCamel(response.data.items)
                        }
                    }
                })
                .catch((error: any) => {
                    $throwError(error, 'can not fetch partners')
                })
                .finally(() => {
                    this.loadingPartner = false
                })
        },
        isPackageFullSpecificPartner(partnerId: number): boolean {
            return (
                this.partners.find((partner) => partner.id === partnerId)
                    ?.packageFull || false
            )
        },
        setCurrentPartner(partner: Partner | null) {
            this.currentPartner = partner
        },
        async fetchPartnerUsers(
            idPartner: string,
            filters: FiltersPartnerUsers
        ) {
            const { keyword, orderColumn, orderDirection, pages } = filters

            const offset = (pages.index - 1) * pages.perPage || (0 as number)
            const limit = pages.perPage || (10 as number)

            const payload = {
                keyword,
                offset,
                limit,
                order_by:
                    orderColumn && orderDirection
                        ? `${orderColumn.toLowerCase()},${orderDirection}`
                        : null
            }

            const { $api, $toCamel, $throwError } = useNuxtApp()
            this.loadingPartner = true

            return $api
                .get(`/partners-api/partners/${idPartner}/users`, {
                    params: payload
                })
                .then((response) => {
                    if (response.status === 200) {
                        const totalCount = parseInt(
                            response.headers['x-total-count'],
                            10
                        )

                        return {
                            totalItems: totalCount,
                            items: $toCamel(response.data.items)
                        }
                    }
                })
                .catch((error: any) => {
                    $throwError(error, 'can not fetch partner users')
                })
                .finally(() => {
                    this.loadingPartner = false
                })
        },
        async inviteNewUsers(
            idPartner: string,
            userMails: string[],
            locale: string
        ) {
            const addedUsers: PartnerUser[] = []
            const errors: string[] = []
            await Promise.all(
                userMails.map((mail) =>
                    this.addPartnerUser(idPartner, mail, locale)
                        .then((response) => {
                            addedUsers.push(response.data)
                        })
                        .catch((_: any) => {
                            errors.push(mail)
                        })
                )
            )

            return { addedUsers, errors }
        },
        async addPartnerUser(idPartner: string, email: string, locale = 'EN') {
            const { $api, $toCamel, $throwError } = useNuxtApp()

            return $api
                .post(`/partners-api/partners/${idPartner}/users`, {
                    email,
                    locale
                })
                .then((response) => {
                    if (response?.status === 201) {
                        return $toCamel(response.data)
                    }
                })
                .catch((error: any) => {
                    $throwError(error, `can not add user ${email}`)
                })
        },
        async fetchPartner(idPartner: number) {
            const { $api, $toCamel, $throwError } = useNuxtApp()
            this.loadingPartner = true

            return $api
                .get(`/partners-api/partners/${idPartner}`)
                .then((response) => {
                    if (response?.status === 200) {
                        this.setCurrentPartner($toCamel(response.data))

                        return $toCamel(response.data)
                    }
                })
                .catch((error: any) => {
                    $throwError(error, 'can not fetch partner')
                })
                .finally(() => {
                    this.loadingPartner = false
                })
        },
        async updatePartner(partner: Partner) {
            const { $api, $throwError } = useNuxtApp()

            this.loadingPartner = true

            const payload = {
                name: partner.name,
                activated: partner.activated,
                package_full: partner.packageFull,
                comment: partner.comment
            } as PartnerJSON

            if (partner.mainContact?.email) {
                payload.main_contact = {
                    email: partner.mainContact.email,
                    first_name: partner.mainContact.firstName,
                    last_name: partner.mainContact.lastName
                }
            }

            return $api
                .patch(`/partners-api/partners/${partner.id}`, payload)
                .then((response) => {
                    this.loadingPartner = false

                    if (response.status === 200) {
                        this.setCurrentPartner(keysToCamel(response.data))

                        return keysToCamel(response.data)
                    }
                })
                .catch((error: any) => {
                    $throwError(error, 'can not update partner')
                })
                .finally(() => {
                    this.loadingPartner = false
                })
        },
        async updatePartnerStatus(partner: Partner) {
            const { $api, $throwError } = useNuxtApp()

            this.loadingPartner = true

            return $api
                .patch(`/partners-api/partners/${partner.id}`, {
                    name: partner.name,
                    activated: partner.activated,
                    comment: partner.comment || null
                })
                .catch((error: any) => {
                    $throwError(error, 'can not update partner status')
                })
                .finally(() => {
                    this.loadingPartner = false
                })
        },
        async createPartner(partner: Partner) {
            const { $api, $throwError } = useNuxtApp()
            this.loadingPartner = true

            const payload = {
                name: partner.name,
                activated: partner.activated,
                package_full: partner.packageFull,
                comment: partner.comment || null
            } as PartnerJSON

            if (partner.mainContact?.email) {
                payload.main_contact = {
                    email: partner.mainContact.email,
                    first_name: partner.mainContact.firstName,
                    last_name: partner.mainContact.lastName
                }
            }

            return $api
                .post(`/partners-api/partners`, payload)
                .then((response) => {
                    this.setCurrentPartner(keysToCamel(response.data))

                    return keysToCamel(response.data)
                })
                .catch((error: any) => {
                    $throwError(error, 'can not create partner')
                })
                .finally(() => {
                    this.loadingPartner = false
                })
        },
        async getPartnerUser(idUser: string, idPartner: string) {
            this.loadingPartner = true

            const { $toCamel, $api, $throwError } = useNuxtApp()

            return $api
                .get(`/partners-api/partners/${idPartner}/users/${idUser}`)
                .then((response) => $toCamel(response.data))
                .catch((error: any) => {
                    $throwError(error, 'can not get partner user')
                })
                .finally(() => {
                    this.loadingPartner = false
                })
        },
        async updatePartnerUser(user: User, idPartner: String) {
            const { $toCamel, $api, $throwError } = useNuxtApp()

            this.loadingPartner = true
            const { id, firstName, lastName, email } = user

            return $api
                .put(`/partners-api/partners/${idPartner}/users/${id}`, {
                    email,
                    first_name: firstName,
                    last_name: lastName
                })
                .then((response) => $toCamel(response.data))
                .catch((error: any) => {
                    $throwError(error, 'can not update partner user')
                })
                .finally(() => {
                    this.loadingPartner = false
                })
        },
        async deletePartnerUser(idUser: String, idPartner: String) {
            const { $throwError, $deleteRequest } = useNuxtApp()

            this.loadingPartner = true

            return $deleteRequest(
                `/partners-api/partners/${idPartner}/users/${idUser}`
            )
                .catch((error: any) => {
                    $throwError(error, 'can not delete partner user')
                })
                .finally(() => {
                    this.loadingPartner = false
                })
        },
        async impersonateUser(idUser: number, login: string, password: string) {
            const { $api } = useNuxtApp()

            this.loadingPartner = true

            return $api
                .post(`/partners-api/impersonate`, {
                    login,
                    password,
                    impersonated_user_id: idUser
                })
                .then((response) => {
                    const session = useSessionStore()
                    session.setUpUser(response.data)
                })
                .catch((e: any) => {
                    throw e
                })
                .finally(() => {
                    this.loadingPartner = false
                })
        }
    },
    persist: {
        storage: localStorage
    }
})

export default usePartnersStore
