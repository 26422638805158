import { defineStore } from 'pinia'
import { type CommunityNetwork, type Network } from '~/interfaces/network'

const useNetworksStore = defineStore('networks', {
    state: () => ({
        loadingNetwork: false,
        currentCommunityNetwork: null as CommunityNetwork | null,
        currentNetwork: null as Network | null
    }),
    actions: {
        async fetchLinkedNetworks(
            idPartner: string,
            keyword: string,
            idNetwork?: number
        ) {
            const { $api, $toCamel, $throwError } = useNuxtApp()

            const payload = {
                keyword: keyword || null,
                limit: 100,
                network_id: idNetwork
            }

            if (!idNetwork) {
                delete payload.network_id
            }

            this.loadingNetwork = true

            return $api
                .get(`/partners-api/partners/${idPartner}/community_networks`, {
                    params: payload
                })
                .then((response) => {
                    if (response.status === 200) {
                        let { items } = response.data
                        items = items.map((item: any) => $toCamel(item))

                        return {
                            items
                        }
                    }
                })
                .catch((error: any) => {
                    $throwError(
                        error,
                        'can not fetch networks linked to partner'
                    )
                })
                .finally(() => {
                    this.loadingNetwork = false
                })
        },
        async fetchCommunityNetworks(name: string) {
            const { $api, $toCamel, $throwError } = useNuxtApp()

            const payload = {
                name
            }
            this.loadingNetwork = true

            return $api
                .get('/partners-api/community_networks', {
                    params: payload
                })
                .then((response) => {
                    if (response.status === 200) {
                        let { items } = response.data
                        items = items.map((item: any) => $toCamel(item))

                        return {
                            items
                        }
                    }
                })
                .catch((error: any) => {
                    $throwError(error, 'can not fetch community networks')
                })
                .finally(() => {
                    this.loadingNetwork = false
                })
        },
        async fetchCurrentNetwork(
            idPartner: string,
            idNetwork: number,
            networkType: string
        ) {
            const { $api, $toCamel, $throwError } = useNuxtApp()

            if (networkType === 'community') {
                this.loadingNetwork = true

                return $api
                    .get(
                        `/partners-api/partners/${idPartner}/community_networks/${idNetwork}`
                    )
                    .then((response) => {
                        if (response.status === 200) {
                            this.setCurrentCommunityNetwork(
                                $toCamel(response.data)
                            )
                        }
                    })
                    .catch((error: any) => {
                        $throwError(error, 'can not fetch network')
                    })
                    .finally(() => {
                        this.loadingNetwork = false
                    })
            }

            if (networkType === 'interop') {
                return this.fetchLinkedNetworks(idPartner, '', idNetwork).then(
                    (res) => {
                        if (res?.items && res.items.length === 1) {
                            this.setCurrentCommunityNetwork(
                                $toCamel(res.items[0])
                            )
                            this.setCurrentNetwork(
                                $toCamel(res.items[0].networks[0])
                            )
                        } else {
                            $throwError(
                                null,
                                `can not fetch network : ${JSON.stringify(res)}`
                            )
                        }
                    }
                )
            }

            $throwError(null, `wrong network type : ${networkType}`)
        },
        async linkNetwork(idPartner: string, network: CommunityNetwork) {
            this.loadingNetwork = true

            const payload = {
                id: network.id,
                networks: network.networks.map((net) => net.id)
            }

            return useNuxtApp()
                .$api.post(
                    `/partners-api/partners/${idPartner}/community_networks`,
                    payload
                )
                .then((response) => {
                    if (response.status === 200) {
                        return response.data
                    }
                })
                .catch((e: any) => {
                    throw e
                })
                .finally(() => {
                    this.loadingNetwork = false
                })
        },
        setCurrentNetwork(network: Network | null) {
            this.currentNetwork = network
        },
        setCurrentCommunityNetwork(network: CommunityNetwork | null) {
            this.setCurrentNetwork(null)
            this.currentCommunityNetwork = network
        },
        unlinkNetwork(idPartner: string, networkId: number) {
            const { $api, $throwError } = useNuxtApp()

            return $api
                .put(
                    `/partners-api/partners/${idPartner}/networks/${networkId}`
                )
                .then((response) => {
                    if (response.status === 200) {
                        return response.data
                    }
                })
                .catch((error: any) => {
                    $throwError(error, 'can not unlink network')
                })
        }
    },
    persist: {
        storage: localStorage
    }
})

export default useNetworksStore
