import useAuthStore from '~/store/auth'
import useSessionStore from '~/store/session'
import { DateTime } from 'luxon'
import usePartnersStore from '~/store/partners'
import useTokenStore from '~/store/token'

export default defineNuxtRouteMiddleware((to, from) => {
    // skip middleware if nuxtApp is not instanced
    const nuxtApp = useNuxtApp()

    if (!nuxtApp || !nuxtApp?.vueApp || !nuxtApp?.$pinia) {
        return
    }

    const authStore = useAuthStore()

    const {
        user,
        isSuperAdmin,
        token,
        refreshToken,
        tokenExpiration,
        refreshTokenExpiration
    } = useSessionStore()

    useTokenStore().token = token

    const { currentPartner } = usePartnersStore()
    const sessionStore = useSessionStore()
    const lastRoute = sessionStore.getLastRoute

    const dateExpired = new Date()
    dateExpired.setDate(dateExpired.getDate() + 1)

    const tokenIsValid =
        !!token &&
        tokenExpiration &&
        DateTime.now().toUnixInteger() <
            DateTime.fromISO(tokenExpiration).toUnixInteger()

    const refreshTokenIsValid =
        !!refreshToken &&
        refreshTokenExpiration &&
        DateTime.now().toUnixInteger() <
            DateTime.fromISO(refreshTokenExpiration).toUnixInteger()

    if (
        !user &&
        !tokenIsValid &&
        !refreshTokenIsValid &&
        to?.name !== 'login' &&
        to?.name !== 'password-reset' &&
        to?.name !== 'password-reset-update'
    ) {
        sessionStore.setLastRoute(from.fullPath)
        abortNavigation()

        return navigateTo('/login')
    }

    // Try to get new token with refresh token
    if (!tokenIsValid && refreshTokenIsValid) {
        authStore
            .refreshUserToken()
            .then(() => {
                if (lastRoute) {
                    navigateTo(lastRoute)
                    sessionStore.setLastRoute(null)
                }

                if (!isSuperAdmin)
                    navigateTo(`/partners/${currentPartner?.id}/networks`)
                navigateTo('/partners')
            })
            .catch(() => {
                sessionStore.setLastRoute(from.fullPath)
                sessionStore.logout()
                abortNavigation()
                navigateTo('/login')
            })
    }

    if (tokenIsValid && to?.name === 'login') {
        if (!isSuperAdmin && currentPartner)
            return navigateTo(`/partners/${currentPartner?.id}/networks`)

        return navigateTo('/partners')
    }
})
