import revive_payload_client_4sVQNw7RlN from "/var/www/mysite/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/var/www/mysite/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/var/www/mysite/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/var/www/mysite/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/var/www/mysite/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/var/www/mysite/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/var/www/mysite/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/var/www/mysite/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/var/www/mysite/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/var/www/mysite/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_t2GMTTFnMT from "/var/www/mysite/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import switch_locale_path_ssr_8BKffvaEi5 from "/var/www/mysite/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.mjs";
import i18n_yfWm7jX06p from "/var/www/mysite/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_AUP22rrBAc from "/var/www/mysite/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import apexchart_client_Dd58KDpJKq from "/var/www/mysite/plugins/apexchart.client.js";
import axios_QMFgzss1s4 from "/var/www/mysite/plugins/axios.ts";
import deleteRequest_suJly9IqNO from "/var/www/mysite/plugins/deleteRequest.ts";
import directives_8CcCirWtnE from "/var/www/mysite/plugins/directives.ts";
import sentry_client_shVUlIjFLk from "/var/www/mysite/plugins/sentry.client.ts";
import throwError_OrbYxjkAVF from "/var/www/mysite/plugins/throwError.ts";
import toCamel_Z3OsNitWQI from "/var/www/mysite/plugins/toCamel.ts";
import toSnake_rbOxIzbmBw from "/var/www/mysite/plugins/toSnake.ts";
import vue3_toastify_OGYNDsiW9E from "/var/www/mysite/plugins/vue3-toastify.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_t2GMTTFnMT,
  switch_locale_path_ssr_8BKffvaEi5,
  i18n_yfWm7jX06p,
  plugin_AUP22rrBAc,
  apexchart_client_Dd58KDpJKq,
  axios_QMFgzss1s4,
  deleteRequest_suJly9IqNO,
  directives_8CcCirWtnE,
  sentry_client_shVUlIjFLk,
  throwError_OrbYxjkAVF,
  toCamel_Z3OsNitWQI,
  toSnake_rbOxIzbmBw,
  vue3_toastify_OGYNDsiW9E
]